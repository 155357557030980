<template>
  <van-form @submit="onSubmit">
    <van-cell-group title="总额设置" inset>
      <van-field
        v-model="product.price"
        name="price"
        label="参考价格"
        placeholder=""
        disabled
      />
      <van-field
        v-model="product.totalCommission"
        name="totalCommission"
        label="分润总额"
        placeholder="请输入"
      />
    </van-cell-group>
    <van-cell-group title="合伙人" inset>
      <van-cell-group title="静态收益" inset>
        <van-field
          v-model="product.dividendTotal"
          name="dividendTotal"
          label="分润总额"
          placeholder="请输入"
        />
        <van-field
          label="成员配额"
          placeholder="点击设置"
          @click="onConfigMember('Dividend')"
        />
      </van-cell-group>
      <van-cell-group title="动态收益" inset>
        <van-field
          v-model="product.salesCommissionTotal"
          name="salesCommissionTotal"
          label="分润总额"
          placeholder="请输入"
        />
        <van-field
          label="成员配额"
          placeholder="点击设置"
          @click="onConfigMember('SalesCommission')"
        />
      </van-cell-group>
    </van-cell-group>
    <van-cell-group title="销售" inset v-if="type">
      <van-field
        v-model="product.salesCollectionCommissionTotal"
        name="salesCollectionCommissionTotal"
        label="分润总额"
        placeholder="请输入"
      />
    </van-cell-group>
    <van-cell-group title="技师" inset>
      <van-field
        v-model="product.technicianCommission"
        name="technicianCommission"
        label="分润总额"
        placeholder="请输入"
      />
    </van-cell-group>
    <van-cell-group title="分享" inset>
      <van-field
        v-model="product.shareCommission"
        name="shareCommission"
        label="分润总额"
        placeholder="请输入"
      />
      <van-field
        v-model="product.shareCommissionRatio"
        name="shareCommissionRatio"
        label="分润比例"
        placeholder="如10-20-30，横向分割"
      />
    </van-cell-group>
    <van-cell-group title="联盟店" inset>
      <van-field
        v-model="product.allianceCommission"
        name="allianceCommission"
        label="分润总额"
        placeholder="请输入"
      />
    </van-cell-group>
    <van-cell-group title="渠道" inset>
      <van-field
        v-model="product.chainCommission"
        name="chainCommission"
        label="分润总额"
        placeholder="请输入"
      />
    </van-cell-group>
    <div style="margin: 16px;">
      <van-button round block type="primary" native-type="submit">
        保存配置
      </van-button>
    </div>
  </van-form>
  <van-popup v-model:show="member.show" position="right" :style="{ width: '80%', height: '100%', background: '#f5f5f5' }">
    <profit-sharing-member @select="onConfigMember"/>
  </van-popup>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import { Toast } from 'vant'
import ProfitSharingMember from '../components/ProfitSharingMember'

export default {
  components: {
    ProfitSharingMember
  },
  setup () {
    const post = inject('post')
    // const Cookies = inject('Cookies')
    const useRoute = inject('useRoute')
    const useRouter = inject('useRouter')
    const state = reactive({
      type: useRoute.query.type,
      productId: useRoute.query.productId,
      product: {},
      price: 0,
      totalCommission: 0,
      member: {
        show: false,
        items: []
      }
    })
    const onConfigMember = (name) => {
      useRouter.push({
        name: name,
        query: {
          productId: state.productId
        }
      })
    }
    const onSubmit = (values) => {
      const data = {
        ...values,
        productId: state.productId,
        shareCommissionRatio: values.shareCommissionRatio !== '' ? values.shareCommissionRatio.split('-') : []
      }
      if (!data.salesCollectionCommissionTotal) data.salesCollectionCommissionTotal = '0'
      post('/product.updateProfitSharing', data).then(res => {
        Toast.clear()
        if (res.code === 0) {
          Toast.success()
        } else {
          Toast(res.msg)
        }
      })
    }
    const init = () => {
      post('/product.get', {
        productId: state.productId
      }).then(res => {
        state.product = res.data
        state.product.shareCommissionRatio = res.data.shareCommissionRatio.join('-')
      })
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onConfigMember,
      onSubmit
    }
  }
}
</script>

<style scoped lang="less">
body {
  background:#f9f9f9;
}
// /deep/.van-cell-group{
//   margin-left: 8px;
// }
</style>

<template>
  <van-form>
    <template v-for="item in items" :key="item">
      <van-cell-group :title="item.userId + ''" inset>
        <van-field
          v-model="item.commission"
          type="number"
          label="分润金额"
          placeholder="请输入"
        />
      </van-cell-group>
    </template>
  </van-form>
</template>

<script>
import { toRefs, reactive, onMounted } from 'vue'

export default {
  emits: ['select'],
  setup (props, { emit }) {
    // const post = inject('post')
    const state = reactive({
      items: [
        {
          userId: 9527,
          commission: 0
        }
      ]
    })
    /*
    const reset = () => {
      state.list = []
      state.page = 1
      state.finished = false
      state.loading = false
    }
    */
    const onClick = (row) => {
      emit('select', row)
    }
    const init = () => {
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onClick
    }
  }
}
</script>

<style scoped>
body {
  background:#ff6600;
}
</style>
